import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as Icons from "@mui/icons-material";
import Swal from "sweetalert2";
import axios from "axios";

export default function Login(props) {
  const showAlert = (data) => {
    Swal.fire({
      title: data.title, //"success",
      text: data.text, //"success",
      icon: data.icon, //"success",
      confirmButtonText: data.button,
    });
  };

  const [backdropOpen, setBackdropOpen] = useState(false);

  const openBackdrop = () => {
    setBackdropOpen(true);
  };

  const closeBackdrop = () => {
    setBackdropOpen(false);
  };

  // Define a state to manage form submission
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleFormSubmission = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log("email:", email);
    const BASEURL = props.BASEURL;

    //Loading
    openBackdrop();
    const formData = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(
        `${BASEURL}api/v1/investors/login`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_access_token_here",
          },
        }
      );

      response.data.credentials = formData;

      localStorage.setItem("LoginUser", JSON.stringify(response.data));
      closeBackdrop();
      // Redirect to dashboard
      window.location = "/account";
    } catch (error) {
      if (error.response) {
        showAlert({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          button: "Ok",
        });
      } else {
        showAlert({
          title: "Error",
          text: error.message,
          icon: "error",
          button: "Ok",
        });
      }
    } finally {
      closeBackdrop();
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backdropOpen}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit={handleFormSubmission}>
              <h5 className="text-center text-white pt-4">
                <b>Account Login</b>
              </h5>
              <div className="text-center text-warning fontFam2 pb-4">
                Login to your account
              </div>

              <br />
              {/* Email Input */}
              <TextField
                label="Enter Your Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                name="email"
                value={email}
                style={{backgroundColor:"white",borderRadius:"5px"}}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{
                  style: {
                    backgroundColor: "#fff", // Change label color
                  // Change font size
                  display:"block",
                    width:"50%",
                    borderRadius:"15px",
                    padding:"2px 15px"
                  },
                }}
              />

              <br />
              {/* Password Input with Eye Icon */}
              <TextField
                label="Enter Your Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                style={{backgroundColor:"white",borderRadius:"5px"}}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        backgroundColor: "transparent", // Transparent background
                        borderRadius: "50%", // Optional, makes the icon circular
                      }}
                    >
                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Icons.VisibilityOff /> : <Icons.Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    backgroundColor: "#fff",
                    display: "block",
                    width: "50%",
                    borderRadius: "15px",
                    padding: "2px 15px",
                  },
                }}
              />

              <FormControlLabel
                control={<Checkbox color="warning" defaultChecked />}
                label="Remember Me"
              />
              <br />
              <br />

              <button className="btn w-100 btn-warning" type="submit">
                SIGN IN
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  BASEURL: PropTypes.string,
};
